// 定义 DalleContentItem 接口
export interface DalleContentItem {
    description: string;
    images: string[];
}

// 示例数据，实际项目中可能来自外部源
export const dalleContentItems: DalleContentItem[] = [
    {
        description: "想像一下，天氣很好的海邊，一個大約30歲的女子，穿著輕鬆，戴著太陽眼鏡，躺在沙灘的躺椅上，有遮陽傘，旁邊有一個小桌，桌上放著一盤炸雞，兩瓶碑酒，一杯果汁，吹著海風，看著海，微笑著，4k，高畫質，日系動漫",
        images: [
            "20240313_001/DALL·E 2024-03-13 09.59.19 - Imagine a scene at a sunny beach with perfect weather. A woman around 30 years old, dressed casually and wearing sunglasses, is lying on a beach chair.webp",
            "20240313_001/DALL·E 2024-03-13 09.59.57 - Imagine a scene at a sunny beach with perfect weather. A woman around 30 years old, dressed casually and wearing sunglasses, is lying on a beach chair.webp"
        ]
    },
    {
        description: "使用元素流暢過渡的雙重曝光圖像設計，大台電動速克達摩托車 kymco g-dink 300，女性騎士，穿著兩截式運動衣服飾, 水花效果,白煙霧,哥德式風格雕刻圖案,平面緞帶,搭配「MUDIK LEBARAN」內部3D書寫,充滿活力的色彩效果。 錯綜複雜的細節、輪廓和紋理非常真實,而且非常清楚,非常高品質、UHDR、超現實主義。",
        images: [
            "20240327_001/DALL·E 2024-03-27 18.27.51 - Create a double exposure image design where elements transition smoothly, featuring a KYMCO G-Dink 300 electric scooter and a female rider. The rider .webp",
            "20240327_001/DALL·E 2024-03-27 18.28.44 - Create a double exposure image design where elements transition smoothly, featuring a KYMCO G-Dink 300 electric scooter and a female rider. The rider .webp"
        ]
    },
    {
        description: "A hyper-realistic, semi-transparent glass pink-white lotus, yellow stamens visible, green stem, with refined details, viewed sideways from a high-angle, in a close-up focusing from bottom to top, Stunning light, against a clean black background, Inspiration: The art of blowing colorful glass.",
        images: [
            "20240329_001/DALL·E 2024-03-29 22.47.26 - Imagine a hyper-realistic image of a semi-transparent glass lotus, with pink-white petals and visible yellow stamens, attached to a green stem. The fl.webp",
            "20240329_001/DALL·E 2024-03-29 22.48.00 - Imagine a hyper-realistic image of a semi-transparent glass lotus, with pink-white petals and visible yellow stamens, attached to a green stem. The fl.webp"
        ]
    },
    {
        description: "超寫實的半透明玻璃粉白蓮花，黃色花蕊可見，綠色莖，細節精緻，從高角度側視，特寫從下到上聚焦，令人驚嘆的光線，在乾淨的背景下黑色背景，靈感：吹制彩色玻璃的藝術。",
        images: [
            "20240329_001/DALL·E 2024-03-29 22.48.31 - Imagine a hyper-realistic image of a semi-transparent glass lotus, with pink-white petals and visible yellow stamens, attached to a green stem. The fl.webp",
            "20240329_001/DALL·E 2024-03-29 22.48.48 - Imagine a hyper-realistic image of a semi-transparent glass lotus, with pink-white petals and visible yellow stamens, attached to a green stem. The fl.webp"
        ]
    },
    {
        description: "超寫實的半透明畫風，年紀23歲可愛少女跳舞，細節精緻，從低角度側視，特寫從下到上聚焦，令人驚嘆的光線，在乾淨的背景下白色背景，靈感：吹制彩色玻璃的藝術",
        images: [
            "20240329_001/DALL·E 2024-03-29 22.49.05 - Envision a hyper-realistic, semi-transparent style image of a 23-year-old cute girl dancing. The details are refined and viewed from a low angle side .webp",
            "20240329_001/DALL·E 2024-03-29 22.49.18 - Envision a hyper-realistic, semi-transparent style image of a 23-year-old cute girl dancing. The details are refined and viewed from a low angle side .webp"
        ]
    },
    {
        description: "超寫實的半透明玻璃玉米，細節精緻，從高角度側視，特寫從下到上聚焦，令人驚嘆的光線，在乾淨的背景下黑色背景，靈感：吹制彩色玻璃的藝術。",
        images: [
            "20240329_001/DALL·E 2024-03-29 22.49.51 - Visualize a hyper-realistic, semi-transparent glass corn cob, with exquisite details, viewed from a high angle and focusing from bottom to top against.webp",
            "20240329_001/DALL·E 2024-03-29 22.50.05 - Visualize a hyper-realistic, semi-transparent glass corn cob, with exquisite details, viewed from a high angle and focusing from bottom to top against.webp"
        ]
    },
    {
        description: "超寫實的半透明玻璃透明玉米，細節精緻，從低角度側視，特寫從下到上聚焦，令人驚嘆的光線，在乾淨的背景下黑色背景，靈感：吹制灰色玻璃的藝術。",
        images: [
            "20240329_001/DALL·E 2024-03-29 22.50.44 - Visualize a photo-realistic image of a cute, fluffy little white cat with extremely fine detail and high clarity. The cat is captured in a real-life s.webp",
            "20240329_001/DALL·E 2024-03-29 22.51.04 - Visualize a photo-realistic image of a cute, fluffy little white cat with extremely fine detail and high clarity. The cat is captured in a real-life s.webp",
        ]
    },
    {
        description: "可愛的小白貓，身體圓圓的，頭圓圓的，四隻腳圓圓的，毛絨絨的，極細緻，高清晰，真實的拍攝，乾淨的黑色背景",
        images: [
            "20240329_001/DALL·E 2024-03-29 22.51.33 - Visualize a photo-realistic image of an adorable little white cat with a round body, round head, and round paws, all covered in fluffy, extremely fine.webp",
            "20240329_001/DALL·E 2024-03-29 22.51.45 - Visualize a photo-realistic image of an adorable little white cat with a round body, round head, and round paws, all covered in fluffy, extremely fine.webp"
        ]
    },
    {
        description: "美式大貨車車頭特寫，極細緻，高畫質，8k",
        images: [
            "20240403_001/DALL·E 2024-04-03 20.27.10 - A close-up of an American-style semi-truck cab, depicted in extreme detail and high definition, with an 8K quality resolution. The image captures the .webp",
            "20240403_001/DALL·E 2024-04-03 20.27.44 - A close-up of an American-style semi-truck cab, depicted in extreme detail and high definition, with an 8K quality resolution. The image captures the .webp"
        ]
    },
    {
        description: "一個可愛、肥胖的台灣中年大叔，穿著白色細背心，短髪，努力的在電腦前寫程式，桌上有大杯咖啡，桌上的平板和手機響不停，被逼瘋仍苦笑著，顧著身後的兩個小女孩，在灰暗的工作室裡為了一家人的生計拼命的打字，真實風格，高畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.47.59 - A cute, chubby middle-aged Taiwanese man with short hair, wearing a white tank top, is working hard on programming at a computer. He has a large cup o.webp",
            "20240628_001/DALL·E 2024-06-28 13.48.38 - A cute, chubby middle-aged Taiwanese man with short hair, wearing a white tank top, is working hard on coding at a computer. On the desk, there is a l.webp",
        ]
    },
    {
        description: "一個可愛、肥胖的台灣中年大叔，穿著白色細背心，短髪，努力的在電腦前寫程式，桌上有大杯咖啡，桌上的平板和手機響不停，被逼瘋仍苦笑著，顧著身後的兩個小女孩，在灰暗的工作室裡為了一家人的生計拼命的打字，真實風格，高畫質，更真實一點的，要像是拍照的效果",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.49.53 - A realistic, high-definition photo of a cute, chubby middle-aged Taiwanese man with short hair, wearing a white tank top, working hard on programming .webp",
            "20240628_001/DALL·E 2024-06-28 13.50.00 - A realistic, high-definition photo of a cute, chubby middle-aged Taiwanese man with short hair, wearing a white tank top, working hard on programming .webp",
        ]
    },
    {
        description: "畫個圖一個美麗的台灣美女，穿著白色細肩帶背心，長髪，努力的在電腦前寫程式，在悶熱的室內滿身大汗，桌上有大杯冰咖啡，手機閃個不停，被逼瘋仍苦笑著，顧著身後的兩個小女孩，為了一家人的生計拼命的打字，真實攝影風格，4k高畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.50.28 - A beautiful Taiwanese woman with long hair, wearing a white tank top, working hard on programming at a computer in a hot indoor environment, sweating .webp",
        ]
    },
    {
        description: "一個美麗的台灣帥爸爸，穿著白色細肩帶背心，長髪，努力的在電腦前寫程式，在悶熱的室內滿身大汗，桌上有大杯冰咖啡，手機閃個不停，被逼瘋仍苦笑著，顧著身後的兩個小女孩，為了一家人的生計拼命的打字，真實攝影風格，4k高畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.50.47 - A handsome Taiwanese father with long hair, wearing a white tank top, working hard on programming at a computer in a hot indoor environment, sweating .webp"
        ]
    },
    {
        description: "建立尺寸 19:9 寬攝影畫面，從左後方拍攝鏡頭，從低向高拍攝視角，一個穿著西裝背心的短髪軟體經理，坐在辦公室裡，有一個投影幕前，瑩幕上顯示著控制台畫面，畫面中有IOT裝置、清單、報表，畫面最上方有個大大的藍色LED燈管的 「MQTT」 字樣，真實攝影，高清4K畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.51.22 - A 19_9 wide-angle photograph taken from the left rear, looking up from a low angle, featuring a short-haired software manager wearing a suit vest. The.webp",
            "20240628_001/DALL·E 2024-06-28 13.51.22 - A 19_9 wide-angle photograph taken from the left rear, looking up from a low angle, featuring a short-haired software manager wearing a suit vest. The.webp"
        ]
    },
    {
        description: "建立一個畫面，一個穿著西裝背心的短髪軟體經理，，從左後方拍攝鏡頭，從低向高視角，坐在辦公室裡，最上方有個大大的藍色LED燈管的 「FLH MQTT」 招牌，有一個投影幕，瑩幕上顯示著控制台畫面，畫面中有 IOT 裝置、清單、報表，真實攝影，高清4K畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.51.49 - A short-haired software manager wearing a suit vest, seated in an office. The photo is taken from the left rear, looking up from a low angle. At the t.webp",
            "20240628_001/DALL·E 2024-06-28 13.51.55 - A short-haired software manager wearing a suit vest, seated in an office. The photo is taken from the left rear, looking up from a low angle. At the t.webp",
        ]
    },
    {
        description: "再修正一下，建立一個畫面，一個穿著西裝背心的短髪軟體經理，，背對著鏡頭，坐在一個投影大瑩幕前，大瑩幕上顯示著幾個控制台畫面，畫面中有IOT裝置、清單、報表，畫面最上方有個大大的藍色led燈管的 「MQTT」 字樣，真實攝影，高清4K畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.52.10 - A short-haired software manager wearing a suit vest, sitting with his back to the camera in front of a large projection screen. The screen displays se.webp",
            "20240628_001/DALL·E 2024-06-28 13.52.16 - A short-haired software manager wearing a suit vest, sitting with his back to the camera in front of a large projection screen. The screen displays se.webp",
        ]
    },
    {
        description: "添加一下元素，建立一個畫面，從左後方拍攝鏡頭，從低向高視角，一個穿著西裝背心的短髪軟體經理，坐在辦公室裡，最上方有個藍色LED燈管的 \"FLH\"\"MQTT\" 招牌，有一個投影幕，瑩幕上顯示著控制台畫面，畫面中有 IOT 裝置、清單、報表，真實攝影，高清4K畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.52.32 - A short-haired software manager wearing a suit vest, seated in an office. The photo is taken from the left rear, looking up from a low angle. At the t.webp",
            "20240628_001/DALL·E 2024-06-28 13.52.38 - A short-haired software manager wearing a suit vest, seated in an office. The photo is taken from the left rear, looking up from a low angle. At the t.webp",
        ]
    },
    {
        description: "建立一個畫面，從左後方45度角拍攝鏡頭，從低向高視角，一個穿著西裝背心的短髪軟體經理，坐在辦公室裡，最上方有個藍色LED燈管的 \"FLH\"\"MQTT\" 招牌，有一個投影幕，瑩幕上顯示著控制台畫面，畫面中有 IOT 裝置、清單、報表，真實攝影，高清4K畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.53.10 - A short-haired software manager wearing a suit vest, seated in an office. The photo is taken from the left rear at a 45-degree angle, looking up from .webp",
            "20240628_001/DALL·E 2024-06-28 13.53.15 - A short-haired software manager wearing a suit vest, seated in an office. The photo is taken from the left rear at a 45-degree angle, looking up from .webp",
        ]
    },
    {
        description: "天氣太熱，在可愛的小房間裡，房間是紛紅色系，一隻可愛的毛絨絨白色免子躺在沙灘椅上吹著冷氣，滿足的笑著，牆上不要有多餘的東西只有吹出冷風的空調冷氣，真實攝影，可愛卡通風格，高清8k畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.55.47 - In a cute little room with a pink color scheme, a fluffy white rabbit is lying on a beach chair, enjoying the cool air from an air conditioner. The ra.webp",
            "20240628_001/DALL·E 2024-06-28 13.55.58 - In a cute little room with a pink color scheme, a fluffy white rabbit is lying on a beach chair, enjoying the cool air from an air conditioner. The ra.webp",
        ]
    },
    {
        description: "為這個「Ferqo Log」專案設計一個 logo",
        images: [
            "20241004_001/DALL·E 2024-10-05 14.21.23 - Design a logo for a project named 'Ferqo Log'. The logo should be modern and tech-inspired, symbolizing data logging, analysis, and efficiency. Use a .webp",
        ]
    },
    {
        description: "產生一個小圖是適合當 網站的 ico 用的",
        images: [
            "20241004_001/DALL·E 2024-10-05 14.22.11 - Create a favicon version of the 'Ferqo Log' logo, simplified for use as a small icon for a website. Keep the design clean and easily recognizable at a.webp",
        ]
    },
    {
        description: "我想把圖案中的主體再放大",
        images: [
            "20241004_001/DALL·E 2024-10-05 14.25.21 - Create a modified version of the 'Ferqo Log' favicon with the central design element enlarged, making it more prominent and easily recognizable as a s.webp",
        ]
    },
    {
        description: "請在圖上加上文字",
        images: [
            "20241004_001/DALL·E 2024-10-05 14.31.08 - Create a favicon for 'Ferqo Log' with the central design element enlarged and add stylized text 'Ferqo Log' in a modern font. Ensure the text is clear.webp",
        ]
    },
    {
        description: "請把圖案的底色用得明亮一些",
        images: [
            "20241004_001/DALL·E 2024-10-05 14.31.08 - Create a favicon for 'Ferqo Log' with the central design element enlarged and add stylized text 'Ferqo Log' in a modern font. Ensure the text is clear.webp",
        ]
    },
    {
        description: "請產生一個在login 頁面適合的橫向圖",
        images: [
            "20241004_001/DALL·E 2024-10-05 14.41.43 - Create a horizontal banner image suitable for the login page of a project named 'Ferqo Log'. The design should be modern and welcoming, incorporating .webp",
        ]
    },
    {
        description: "圖的結構請簡化，最好是只有線條形式的",
        images: [
            "20241004_001/DALL·E 2024-10-05 14.42.07 - Create a simplified horizontal banner image suitable for the login page of a project named 'Ferqo Log'. The design should use clean, modern line art, .webp",
        ]
    },
    {
        description: "請調整成裡有一個 「Ferqo Log」和三個幾何線條圖底色請用柔和色系",
        images: [
            "20241004_001/DALL·E 2024-10-05 14.42.40 - Create a horizontal banner image for the login page of a project named 'Ferqo Log'. The design should feature the text 'Ferqo Log' prominently, accomp.webp",
        ]
    },
    {
        description: "請生成一個圖底色為柔和色系，橫幅直角邊，中間一個 「Ferqo Log」字樣，只有三個幾何線條圖",
        images: [
            "20241004_001/DALL·E 2024-10-05 14.43.01 - Create an image with a horizontal rectangular shape and a soft color background. In the center, place the text 'Ferqo Log' in a modern, minimalist fon.webp",
        ]
    },
    {
        description: "請把中間的主圖再放大",
        images: [
            "20241004_001/DALL·E 2024-10-05 14.34.47 - Create an image with a horizontal rectangular shape and a soft color background. In the center, place the text 'Ferqo Log' in a modern, minimalist fon.webp",
        ]
    },
    {
        description: "Visualize this with flames coming out of the back",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.14.58 - A dynamic image showing an object with flames bursting out from its back. The flames are bright, vivid, and dramatic, with a sense of motion and energ.webp",
            ]
    },
    {
        description: "想像一個超級跑車衝出來，藍綠火焰跟在後面",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.15.27 - A supercar bursting forward with a powerful sense of speed. Bright blue and green flames trail behind it, creating an intense and vibrant effect. The .webp",
            ]
    },
    {
        description: "想像一個超級戰鬥機衝出項端，金色火焰跟在後面",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.15.48 - A super jet fighter zooming forward with intense speed, golden flames trailing behind it. The flames are bright and vibrant, with a metallic golden hu.webp",
            ]
    },
    {
        description: "想像一個超級戰鬥機衝出項端，金色火焰跟在後面，生成橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.16.04 - A super jet fighter zooming forward at intense speed with a trail of golden flames behind it, in a wide banner format. The flames are vivid and metall.webp",
            ]
    },
    {
        description: "一個橫幅圖片，想像一個超級戰鬥機衝出雲端，金色火焰跟在後面",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.16.17 - A super jet fighter bursting through clouds at high speed in a wide banner format, with vibrant golden flames trailing behind it. The jet appears to b.webp",
            ]
    },
    {
        description: "一個橫幅圖片，想像一個超級戰鬥機從右邊衝出來，金色火焰跟在後面",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.16.41 - A wide banner image of a super jet fighter bursting out from the right side of the frame with intense speed. Vibrant golden flames trail dynamically b.webp",
            ]
    },
    {
        description: "一個橫幅圖片，想像一個超級戰鬥機金色火焰跟在後面從左下方衝出來",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.16.55 - A wide banner image of a super jet fighter bursting out from the bottom left corner with golden flames trailing behind it. The jet is sleek, modern, a.webp",
            ]
    },
    {
        description: "橫幅圖片，想像火焰從超級戰鬥機後面冒出來",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.17.10 - A wide banner image of a super jet fighter with flames bursting out from its back. The jet is sleek and aerodynamic, flying forward at high speed. The.webp",
            ]
    },
    {
        description: "想像火焰從超級軍艦後面冒出來，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.17.53 - A wide banner image showing a super battleship with flames bursting out from its back. The ship is massive, modern, and designed for high speed, cutti.webp",
            ]
    },
    {
        description: "想像火焰從超級太空梭後面冒出來，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.18.08 - A wide banner image of a super space shuttle with flames bursting out from its back. The space shuttle is sleek, futuristic, and designed for high-spe.webp",
            ]
    },
    {
        description: "想像火焰跟著超級軍艦後面衝出來，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.19.15 - A wide banner image of a super battleship with flames trailing dynamically from its back as it speeds across the ocean. The battleship is modern, slee.webp",
            ]
    },
    {
        description: "想像火焰從超級戰鬥機後面衝出來，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.19.58 - A wide banner image of a super jet fighter with intense flames bursting out from its back. The jet is sleek, modern, and designed for high-speed maneu.webp",
            ]
    },
    {
        description: "想像一個超級戰鬥機衝出項端，金色火焰跟在後面，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.20.53 - A wide banner image of a super jet fighter bursting through clouds at high speed, with golden flames trailing behind. The jet has a sleek, aerodynamic.webp",
            ]
    },
    {
        description: "想像一個超級摩託車跟著藍綠火焰衝出來，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.22.03 - A wide banner image of a super motorcycle bursting forward at high speed, with vibrant blue and green flames trailing behind it. The motorcycle is sle.webp",
            ]
    },
    {
        description: "想像一個超級跑車跟著藍色火焰衝出來，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.22.55 - A wide banner image of a supercar bursting forward at high speed, with vibrant blue flames trailing behind it. The supercar has a sleek, aerodynamic d.webp",
            ]
    },
    {
        description: "想像一個可愛小女生頭髪跟著金紅色火焰衝出來",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.32.10 - A cute young girl with hair flowing behind her like vibrant golden-red flames. The girl has a sweet and innocent expression, with big, sparkling eyes .webp",
            ]
    },
    {
        description: "想像一個可愛小女生頭髪跟著金紅色火焰衝出來，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.32.33 - A wide banner image of a cute young girl with her hair flowing behind her like vibrant golden-red flames. The girl has a sweet, innocent expression wi.webp",
            ]
    },
    {
        description: "想像一個可愛小女生跟著金紅色火焰衝出來，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.32.50 - A wide banner image of a cute young girl dashing forward with vibrant golden-red flames trailing behind her. The girl has a joyful and playful express.webp",
            ]
    },
    {
        description: "想像一個可愛小女生腳踩著兩個輪子並且有金紅色火焰衝出來，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.33.04 - A wide banner image of a cute young girl riding on two wheels, with vibrant golden-red flames bursting out from behind her. The girl has a joyful, adv.webp",
            ]
    },
    {
        description: "想像一個可愛小女生雙腳踩著兩個輪子並且有金紅色火焰，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.33.18 - A wide banner image of a cute young girl standing with both feet on two wheels, surrounded by vibrant golden-red flames. The girl has a joyful and pla.webp",
            ]
    },
    {
        description: "想像一個可愛小女生雙腳各踩著一個有金紅色火焰的輪子，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.34.02 - A wide banner image of a cute young girl, each foot balancing on a wheel emitting vibrant golden-red flames. The girl has a joyful and playful express.webp",
        ]
    },
    {
        description: "想像一個可愛小女生腳下踩著金紅色火焰的輪子，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.34.16 - A wide banner image of a cute young girl standing on a wheel with golden-red flames bursting out from beneath it. The girl has a joyful and playful ex.webp",
        ]
    },
    {
        description: "想像一個可愛小女生雙腳各踩著金紅色火焰的輪子，生成一個橫幅圖片",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.34.30 - A wide banner image featuring a cute young girl, each foot on a wheel with golden-red flames bursting out. The girl has a cheerful and playful express.webp",
        ]
    },
    {
        description: "Visualize  哪吒 (Nezha) with flames coming out of the back",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.34.49 - A dynamic depiction of Nezha, a young figure from Chinese mythology, with an energetic and fierce expression. He is shown riding his signature wind-fi.webp",
        ]
    },
    {
        description: "Visualize  a cute version of 哪吒 (Nezha) with flames coming out of the back",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.35.01 - A cute and playful depiction of Nezha, the young figure from Chinese mythology. This version of Nezha has a child-like appearance with a friendly and .webp",
        ]
    },
    {
        description: "Visualize  a super cute version of 哪吒 (Nezha) with flames coming out of the back, wide banner image ",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.35.24 - A super cute and playful depiction of Nezha from Chinese mythology, in a wide banner format. Nezha is shown with a child-like, adorable appearance, fe.webp",
        ]
    },
    {
        description: "Visualize  a super cute girl with flames coming out of the back, wide banner image ",
        images: [
            "20241005_001/DALL·E 2024-10-05 15.35.49 - A wide banner image featuring a super cute girl with flames bursting out from her back. The girl has a joyful, playful expression with big sparkling e.webp",
        ]
    },
    {
        description: "請設計一個橫幅圖片，符合達利設計感，加上 「AI Gallery」字樣",
        images: [
            "20241005_002/DALL·E 2024-10-06 16.25.10 - A surrealistic banner inspired by the style of Salvador Dali, featuring flowing, dream-like landscapes and melting shapes, evoking a sense of abstract.webp",
            "20241005_002/DALL·E 2024-10-06 16.25.31 - A surrealistic banner inspired by the style of Salvador Dali, featuring flowing, dream-like landscapes and melting shapes, evoking a sense of abstract.webp",
        ]
    },
    {
        description: "設計一個橫幅圖片，符合前衛設計風格，並且加上「Bing」字樣",
        images: [
            "20241005_002/DALL·E 2024-10-06 16.26.12 - A banner with a futuristic and avant-garde design style, featuring sleek lines, geometric patterns, and bold color contrasts like neon blues, metallic.webp",
        ]
    },
    {
        description: "設計一個橫幅圖片，符合大膽時尚設計風格，並且加上「Dalle」字樣",
        images: [
            "20241005_002/DALL·E 2024-10-06 16.26.30 - A banner with a bold and fashionable design style, characterized by striking colors such as vibrant reds, golds, and deep blacks. The word 'Dalle' is .webp",
            "20241005_002/DALL·E 2024-10-06 16.26.36 - A banner with a bold and fashionable design style, characterized by striking colors such as vibrant reds, golds, and deep blacks. The word 'Dalle' is .webp",
        ]
    },
    {
        description: "設計一個橫幅圖片，符合Dalle設計風格，並且加上「Dalle」字樣",
        images: [
            "20241005_002/DALL·E 2024-10-06 16.26.53 - A banner designed in the style inspired by Dalle's imaginative and artistic approach, featuring surreal and colorful elements that blend abstract shap.webp",
            "20241005_002/DALL·E 2024-10-06 16.26.59 - A banner designed in the style inspired by Dalle's imaginative and artistic approach, featuring surreal and colorful elements that blend abstract shap.webp",
        ]
    },
    {
        description: "設計圖片，符合前衛設計風格，並且加上「Bing」字樣",
        images: [
            "20241005_002/DALL·E 2024-10-06 16.27.14 - An image in an avant-garde design style, featuring sleek, modern elements with geometric patterns and a bold color palette including neon blues, silve.webp",
            "20241005_002/DALL·E 2024-10-06 16.27.26 - An image in an avant-garde design style, featuring sleek, modern elements with geometric patterns and a bold color palette including neon blues, silve.webp",
        ]
    },
    {
        description: "設計圖片，符合Dalle設計風格，並且加上「Dalle」字樣",
        images: [
            "20241005_002/DALL·E 2024-10-06 16.27.54 - An artistic image inspired by the Dalle design style, featuring surreal and imaginative elements with a vibrant and colorful palette including blues, .webp",
            "20241005_002/DALL·E 2024-10-06 16.28.01 - An artistic image inspired by the Dalle design style, featuring surreal and imaginative elements with a vibrant and colorful palette including blues, .webp",
        ]
    },
    {
        description: "設計一個橫幅圖片，符合Dalle多色彩設計風格，並且加上「Dalle」字樣",
        images: [
            "20241005_002/DALL·E 2024-10-06 16.28.16 - A colorful banner inspired by Dalle's vibrant design style, featuring an array of bright and bold colors such as blues, pinks, yellows, greens, and pu.webp",
            "20241005_002/DALL·E 2024-10-06 16.28.22 - A colorful banner inspired by Dalle's vibrant design style, featuring an array of bright and bold colors such as blues, pinks, yellows, greens, and pu.webp",
            "20241005_002/DALL·E 2024-10-06 16.28.40 - A vibrant, multicolored banner inspired by Dalle's artistic style, filled with a palette of bright colors such as blues, pinks, yellows, greens, and p.webp",
            "20241005_002/DALL·E 2024-10-06 16.29.03 - A vibrant, multicolored banner inspired by Dalle's artistic style, filled with a palette of bright colors such as blues, pinks, yellows, greens, and p.webp"
        ]
    },
    {
        description: "一個有點白髪的中年男人，對著電腦用力的敲打機械鍵盤，讓旁邊的人很無奈",
        images: [
            "20250319_001/DALL·E 2025-03-19 09.20.16 - A middle-aged man with some gray hair furiously typing on a mechanical keyboard at a computer. The scene shows the man intensely focused, with exagger.webp",
            "20250319_001/DALL·E 2025-03-19 09.20.35 - A middle-aged man with some gray hair furiously typing on a mechanical keyboard at a computer. The scene shows the man intensely focused, with exagger.webp",
        ]
    },
    {
        description: "一個有點白髪的中年男人，用力的敲打機械鍵盤，冒出一堆聲音，讓旁邊的人很受不了",
        images: [
            "20250319_001/DALL·E 2025-03-19 09.21.08 - A middle-aged man with some gray hair furiously typing on a mechanical keyboard, with sound effects like 'CLACK CLACK CLACK' visually appearing around.webp",
            "20250319_001/DALL·E 2025-03-19 09.21.14 - A middle-aged man with some gray hair furiously typing on a mechanical keyboard, with sound effects like 'CLACK CLACK CLACK' visually appearing around.webp"
        ]
    },
    {
        description: "一個有點白髪的中年男人，愉快的敲打機械鍵盤，讓旁邊的人很受不了",
        images: [
            "20250319_001/DALL·E 2025-03-19 09.21.26 - A middle-aged man with some gray hair happily typing on a mechanical keyboard, smiling and enjoying himself as he types. The sound effects like 'CLACK.webp",
            "20250319_001/DALL·E 2025-03-19 09.21.32 - A middle-aged man with some gray hair happily typing on a mechanical keyboard, smiling and enjoying himself as he types. The sound effects like 'CLACK.webp"
        ]
    }
    /*
    {
        description: "",
        images: [
            "",
            "",
            "",
            "",
        ]
    },
*/
];
